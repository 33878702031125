/**
 * HACK: These paths are explicitly opted out of the default SvelteKit root routing,
 * (i.e., supp.co/:value/), allowing all other unrecognized paths be redirected to /partners.
 *
 * I mean, if you can find a better way of doing this with idiomatic SvelteKit
 * _while not maintaining a repeatedly refreshed list of partner paths_, I'm all ears.
 *
 * Also see:
 *  - https://linear.app/supple/issue/SUP-2455/power-partner-page-directs
 *  - https://svelte.dev/docs/kit/advanced-routing#Matching
 *  - Failed attempt with explanation comment: https://github.com/SuppleCo/web/pull/99
 */
const SystemRootPaths = [
  'login',
  'logout',
  'api',
  'dpi',
  'dpi-staging',
  'sentry',
  'discourse-sso',
];

export function match(value: string) {
  // Super explicit syntax since this is system critical.
  return SystemRootPaths.includes(value) === false;
}