import * as client_hooks from '../../../src/hooks.client.js';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105')
];

export const server_loads = [0,10];

export const dictionary = {
		"/": [12],
		"/about/beta": [89],
		"/about/founder-story": [90],
		"/about/manifesto": [91],
		"/about/[slug]": [88],
		"/af/[slug]": [92],
		"/app-home": [93],
		"/app-home/vibes": [94],
		"/(app)/assets/g/lists/og1/[id]": [46,[6,7]],
		"/(app)/assets/g/trust-score/og1/[id]": [47,[6,8]],
		"/(app)/assets/g/u/[slug]/stack/og1": [48,[6,9]],
		"/(app)/brands": [49,[2]],
		"/(app)/brands/[slug]": [50,[2]],
		"/(app)/brands/[slug]/trustscore": [51,[2]],
		"/(app)/collective": [~52,[2,10]],
		"/(app)/collective/posts/[slugWithId]": [~53,[2,10]],
		"/contact": [95],
		"/delete-account": [96],
		"/(app)/discover": [54,[2]],
		"/(app)/download": [55,[2]],
		"/(app)/(private)/feedback": [13,[2,3]],
		"/get-started": [97],
		"/get-started/find-trusted-products": [98],
		"/get-started/get-recommendations": [99],
		"/get-started/get-reminders": [100],
		"/get-started/optimize-my-stack": [101],
		"/get-started/save-money": [102],
		"/(app)/(private)/home": [14,[2,3]],
		"/(app)/(private)/internal/apps-flyer": [15,[2,3]],
		"/(app)/(private)/internal/chat": [16,[2,3]],
		"/(app)/lists/[id]": [56,[2]],
		"/lp/[slug]": [103],
		"/(app)/(private)/mocks/auth/signin": [17,[4]],
		"/(app)/(private)/my/cabinet": [18,[2,3]],
		"/(app)/(private)/my/lists": [19,[2,3]],
		"/(app)/(private)/my/lists/[id]": [20,[2,3]],
		"/(app)/(private)/my/lists/[id]/edit": [21,[2,3]],
		"/(app)/(private)/my/protocols": [22,[2,3]],
		"/(app)/(private)/my/protocols/recommended": [23,[2,3]],
		"/(app)/(private)/my/schedule/[[date]]": [24,[2,3]],
		"/(app)/(private)/my/stack/nutrients": [25,[2,3,5]],
		"/(app)/(private)/my/stack/products": [26,[2,3,5]],
		"/(app)/(private)/my/stack/protocols": [27,[2,3,5]],
		"/(app)/(private)/my/suppscore": [28,[2,3]],
		"/(app)/(private)/my/suppscore/complete": [29,[2,3]],
		"/(app)/(private)/my/suppscore/wizard": [30,[2,3]],
		"/(app)/(private)/my/suppscore/wizard/[slide]": [31,[2,3]],
		"/(app)/nutrients": [57,[2]],
		"/(app)/nutrients/popular": [59,[2]],
		"/(app)/nutrients/[slug]": [58,[2]],
		"/partner/[slug]": [104],
		"/(app)/products": [60,[2]],
		"/(app)/products/category/[slug]": [69,[2]],
		"/(app)/(private)/products/edit": [32,[2,3]],
		"/(app)/products/popular": [70,[2]],
		"/(app)/products/search": [71,[2]],
		"/(app)/(private)/products/submit/[[id]]": [33,[2,3]],
		"/(app)/products/[slug]": [61,[2]],
		"/(app)/products/[slug]/report-image-incorrect": [62,[2]],
		"/(app)/products/[slug]/report-label-incorrect": [63,[2]],
		"/(app)/products/[slug]/report-nutrient-amount-incorrect": [64,[2]],
		"/(app)/products/[slug]/report-nutrient-incorrect": [65,[2]],
		"/(app)/products/[slug]/report-other-problem": [66,[2]],
		"/(app)/products/[slug]/report-problem": [67,[2]],
		"/(app)/products/[slug]/report-purchase-link-missing": [68,[2]],
		"/(app)/(private)/profile": [34,[2,3]],
		"/(app)/(private)/profile/logs": [~35,[2,3]],
		"/(app)/protocols": [72,[2]],
		"/(app)/protocols/[slug]": [73,[2]],
		"/(app)/(private)/saves": [36,[2,3]],
		"/(app)/scanner": [74,[2]],
		"/(app)/scanner/lookup": [75,[2]],
		"/sentry/example": [105],
		"/(app)/(private)/settings": [37,[2,3]],
		"/(app)/(private)/settings/allow-push-notifications": [38,[2,3]],
		"/(app)/(private)/settings/profile": [39,[2,3]],
		"/(app)/(private)/submitted-products/[[id]]/front-img": [40,[2,3]],
		"/(app)/(private)/submitted-products/[id]/details": [41,[2,3]],
		"/(app)/(private)/submitted-products/[id]/facts-img": [43,[2,3]],
		"/(app)/(private)/submitted-products/[id]/facts": [42,[2,3]],
		"/(app)/(private)/submitted-products/[id]/matching-products": [44,[2,3]],
		"/(app)/(private)/submitted-products/[id]/review": [45,[2,3]],
		"/(app)/supplements": [76,[2]],
		"/(app)/supplements/popular": [78,[2]],
		"/(app)/supplements/[slug]": [77,[2]],
		"/(app)/trustscore": [79,[2]],
		"/(app)/u/popular": [86,[2]],
		"/(app)/u/[slug]/(stack)": [80,[2,11]],
		"/(app)/u/[slug]/(stack)/nutrients": [81,[2,11]],
		"/(app)/u/[slug]/(stack)/products": [82,[2,11]],
		"/(app)/u/[slug]/profile": [85,[2]],
		"/(app)/u/[slug]/(stack)/protocols": [83,[2,11]],
		"/(app)/u/[slug]/(stack)/summary": [84,[2,11]],
		"/[slug=notsystempath]": [87]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: universal_hooks.reroute || (() => {}),
	transport: universal_hooks.transport || {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';